<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  /**
   * Form-element component
   */
  export default {
    page: {
      title: "解付记录上传",
      meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "解付记录上传",
        items: [
          {
            text: "首页",
            href: "/"
          },
          {
            text: "解付记录上传",
            active: true
          }
        ],
        status: "not_accepted",
        checkCustom: "not_accepted",
        checked: true,
        activeName: '地产',
        value: true,
        FormData: {
          // 集团名称
          group_name: "",
          // 票系
          piaoxi: "",
          // 行业类别
          leib: '地产',
          // 是否上市
          Listed_of: true,
          // 上市编号
          Listing_number: "",
          // 文件file
          paper_file: "",
          // Logofile
          Logo_file: "",
          enterprise: "民营",
          guojpj: "",
          guonpj: "",
        },
        loading: false
      };
    },
    mounted () {
      console.log(this.$refs)
    } ,
    methods: {
      // 文件上传成功后清空
      fileSuccess(response, file, fileList) {
        this.$refs.upload.clearFiles()
      },
      // 文件上传前获取文件file
      httpRequest(param) {
        this.FormData.paper_file = param.file
      },
      // 删除文件列表
      handleRemove() {
        this.$refs.upload.clearFiles()
      },
      //移除上一个文件
      removeLastFile(files, fileList) {
        if (fileList) {
          this.filesUpload = fileList.slice(-1)
        }
      },
      // 提交上传
      submitUpload() {
        let that = this
        this.$refs.upload.submit();
        this.loading = true
        let Listed_of = this.FormData.Listing_number == '' ? 0 : 1
        let fd = new FormData()
        fd.append('upload', this.FormData.paper_file)
        if (this.loading) {
          _API_.CashUpload(fd)
            .then((res) => {
              if (res.code == 200) {
                let that = this
                this.loading = false
                this.FormData = {
                  // 文件file
                  paper_file: "",
                }
                utils.ShowMsg(res.msg,'success',()=>{
                  this.$refs.upload.clearFiles()
                })
              }else
              {
                utils.ShowMsg(res,'error')
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="padding: 20px 0;">
          <!-- 文件上传 -->
          <div style="display: flex;align-items: center;justify-content: center;">
            <div style="width: 100px;">
              文件上传：
            </div>
            <el-upload ref="upload" class="upload-demo" :on-success="fileSuccess" :handleRemove="handleRemove"
              :on-change="removeLastFile" :limit="1" :auto-upload="false" :http-request="httpRequest" drag
              action="https://jsonplaceholder.typicode.com/posts/" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </div>

          <div style="display: flex;align-items: center;justify-content: center;margin-top:10px;">
            <el-button @click="submitUpload" type="success">提交</el-button>
          </div>



        </div>
      </div>

    </div>

    <!-- end row -->
  </Layout>
</template>